import fetch from 'dva/fetch';
import { notification } from 'antd';
import router from 'umi/router';
import { isAntdPro } from './utils';
import showErrorMsg from '../utils/error';
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

const checkStatus = response => {
  if (response.status >= 200 && response.status <= 400) {
    return response;
  }
  const errortext = codeMessage[response.status] || response.statusText;
  notification.error({
    message: `请求错误 ${response.status}: ${response.url}`,
    description: errortext,
  });
  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  throw error;
};

// const cachedSave = (response, hashcode) => {
//     /**
//      * Clone a response data and store it in sessionStorage
//      * Does not support data other than json, Cache only json
//      */
//     const contentType = response.headers.get('Content-Type');
//     if (contentType && contentType.match(/application\/json/i)) {
//         // All data is saved as text
//         response
//             .clone()
//             .text()
//             .then(content => {
//                 sessionStorage.setItem(hashcode, content);
//                 sessionStorage.setItem(`${hashcode}:timestamp`, Date.now());
//             });
//     }
//     return response;
// };

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(
  url,
  options = {
    expirys: isAntdPro(),
  },
  reportError
) {
  /**
   * Produce fingerprints based on url and parameters
   * Maybe url has the same parameters
   */
  url = DOMAIN + url;
  // const fingerprint = url + (options.body ? JSON.stringify(options.body) : '');
  // // url='api'+url;
  // const hashcode = hash
  //     .sha256()
  //     .update(fingerprint)
  //     .digest('hex');

  const defaultOptions = {
    credentials: 'same-origin',
  };
  if (options.headers) {
    options.headers.Authorization = authorization;
    options.headers['Req-Host'] = location.host;
    // options.headers['Req-Host'] = 'xiaokeduo.com';
  } else {
    options.headers = {
      Authorization: authorization,
      'Req-Host': location.host
      // 'Req-Host': 'xiaokeduo.com',
    };
  }
  const newOptions = {
    ...defaultOptions,
    ...options,
  };
  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'DELETE'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'Req-Host': location.host,
        ...newOptions.headers,
      };
      // newOptions.body = JSON.stringify(newOptions.body);
      if (
        newOptions.headers['Content-Type'] !== 'application/x-www-form-urlencoded' ||
        newOptions.headers['credentials'] !== 'omit'
      ) {
        newOptions.body =
          typeof newOptions.body === 'string' ? newOptions.body : JSON.stringify(newOptions.body);
      }
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        ...newOptions.headers,
      };
    }
  }

  // const expirys = options.expirys || 60;
  // // options.expirys !== false, return the cache,
  // if (options.expirys !== false) {
  //     const cached = sessionStorage.getItem(hashcode);
  //     const whenCached = sessionStorage.getItem(`${hashcode}:timestamp`);
  //     if (cached !== null && whenCached !== null) {
  //         const age = (Date.now() - whenCached) / 1000;
  //         if (age < expirys) {
  //             const response = new Response(new Blob([cached]));
  //             return response.json();
  //         }
  //         sessionStorage.removeItem(hashcode);
  //         sessionStorage.removeItem(`${hashcode}:timestamp`);
  //     }
  // }
  return (
    fetch(url, newOptions)
      .then(checkStatus)
      //.then(response => cachedSave(response, hashcode))
      .then(response => {
        // DELETE and 204 do not return data by default
        // using .json will report an error.
        if (newOptions.method === 'DELETE' || response.status === 204) {
          return response.text();
        }
        response
          .clone()
          .text()
          .then(function(res) {
            //全局抛出接口错误 错误信息对应errorjs文件
            // res = JSON.parse(res);
            // if (res.Code != 0 && res.Code != '50010') showErrorMsg(res)
          });
        return response.json();
      })
      .catch(e => {

        const status = e.name;
        //接口http错误输出日志供Fundebug错误接收
        // environment should not be used
        if(status=='TypeError') {
          router.push('/exception/500');
          return;
        }
        if (status === 403) {
          window.g_app._store.dispatch({
            type: 'login/logout',
          });
          return;
        }
        if (newOptions.release) return;
        if (status <= 504 && status >= 500) {
          router.push('/exception/500');
          return;
        }
        if (status >= 404 && status < 422) {
          router.push('/exception/404');
        }
      })
  );
}
