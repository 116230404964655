import {
  AliCheckLogin,
  SendAliSmsCode,
  SendRegSmsCode,
  SendAliSmsCodes
} from '@/services/index';
import { catchException } from '@/utils/utils';
export default {
  namespace: 'newlogin',

  state: {
  },

  effects: {



    *AliCheckLogin({ payload, callback }, { call, put }) {
      const response = yield call(AliCheckLogin, payload);
      yield catchException(function* () {
        callback && callback.success(response);
      })
    },

    *SendAliSmsCode({ payload, callback }, { call, put }) {
      const response = yield call(SendAliSmsCode, payload);
      yield catchException(function* () {
        callback && callback.success(response);
      })
    },

    *SendRegSmsCode({ payload, callback }, { call, put }) {
      const response = yield call(SendRegSmsCode, payload);
      yield catchException(function* () {
        callback && callback.success(response);
      })
    },

    
    *SendAliSmsCodes({ payload, callback }, { call, put }) {
      const response = yield call(SendAliSmsCodes, payload);
      yield catchException(function* () {
        callback && callback.success(response);
      })
    },


  },

  reducers: {
  },
};
