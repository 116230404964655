import request from '@/utils/request';
import {stringify} from 'qs';
import {formDataCode} from '@/utils/utils';

// 重构后接口

//获取初始数据--新
export async function GetInitData(params) {
    return request('/api/Login/GetInitData');
}

//--登录---

// 获取二维码扫描状态
export async function GetLoginScanEvent(params) {
    return request('/api/Login/GetLoginScanEvent', {
      method: 'POST',
      headers:{
        'Content-Type':"application/x-www-form-urlencoded",        
     },
     body: formDataCode(params)
    });
}

  // 账号密码校验
  export async function CheckNewLogin(params) {
    return request('/api/Login/CheckNewLogin', {
      method: 'POST',
      headers:{
        'Content-Type':"application/x-www-form-urlencoded",        
     },
     body: formDataCode(params)
    });
  }

  // 账号登录验证码发送
  export async function LoginSendSmsCode(params) {
    return request('/api/Login/SendSmsCode', {
      method: 'POST',
      headers:{
        'Content-Type':"application/x-www-form-urlencoded",        
     },
     body: formDataCode(params)
    });
  }

  // 账号登录验证码验证
  export async function VerificationCode(params) {
    return request('/api/Login/VerificationCode', {
      method: 'POST',
      headers:{
        'Content-Type':"application/x-www-form-urlencoded",        
     },
     body: formDataCode(params)
    });
  }

  //获取二维码扫描确认结果
  export async function GetScanResult(params) {
    return request('/api/Login/GetScanResult', {
      method: 'POST',
      headers:{
        'Content-Type':"application/x-www-form-urlencoded",        
     },
     body: formDataCode(params)
    });
  }




// --注册--

//验证手机号是否已在同一厂商下注册过
export async function CheckTelPhone(params) {
    return request(`/api/Register/CheckTelPhone?${stringify(params)}`);
}

//获取验证码
export async function SendSmsCode(params) {
    return request('/api/Register/SendSmsCode', {
      method: 'POST',
      headers:{
        'Content-Type':"application/x-www-form-urlencoded",        
     },
     body: formDataCode(params)
    });
  }

  //注册
  export async function Register(params) {
    return request('/api/Register/Register', {
      method: 'POST',
      headers:{
        'Content-Type':"application/x-www-form-urlencoded",        
     },
     body: formDataCode(params)
    });
  }

  //校验手机号是否已注册
export async function FindPwdCheckTelPhone(params) {
    return request(`/api/FindPwd/CheckTelPhone?${stringify(params)}`);
}

//获取验证码
export async function FindPwdSendSmsCode(params) {
    return request('/api/FindPwd/SendSmsCode', {
      method: 'POST',
      headers:{
        'Content-Type':"application/x-www-form-urlencoded",        
     },
     body: formDataCode(params)
    });
  }

  //重置密码
  export async function SetNewPwd(params) {
    return request('/api/FindPwd/SetNewPwd', {
      method: 'POST',
      headers:{
        'Content-Type':"application/x-www-form-urlencoded",        
     },
     body: formDataCode(params)
    });
  }

  //协议
  export async function GetServiceTerm(params) {
    return request(`/api/register/GetServiceTerm?${stringify(params)}`);
}