import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from 'E:/xiaokeduo-project/custom-shanfang/oms_web/passport/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    redirect: '/login',
    exact: true,
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/UserLayout'),
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        path: '/login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../User/login/index'),
            })
          : require('../User/login/index').default,
        exact: true,
      },
      {
        path: '/register',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../User/register/index'),
            })
          : require('../User/register/index').default,
        exact: true,
      },
      {
        path: '/forget',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../User/register/findPwd'),
            })
          : require('../User/register/findPwd').default,
        exact: true,
      },
      {
        path: '/register/serviceTerm',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../User/ServiceTerm'),
            })
          : require('../User/ServiceTerm').default,
        exact: true,
      },
      {
        path: '/register/success',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../User/RegisterSuccess'),
            })
          : require('../User/RegisterSuccess').default,
        exact: true,
      },
      {
        path: '/forget/success',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../User/FindPwdSuccess'),
            })
          : require('../User/FindPwdSuccess').default,
        exact: true,
      },
      {
        path: '/exception/403',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../Exception/403'),
            })
          : require('../Exception/403').default,
        exact: true,
      },
      {
        path: '/exception/404',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../Exception/404'),
            })
          : require('../Exception/404').default,
        exact: true,
      },
      {
        path: '/exception/500',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../Exception/500'),
            })
          : require('../Exception/500').default,
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../Exception/404'),
            })
          : require('../Exception/404').default,
        exact: true,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
