import {
  getOEMData,
  SendUserTel,
  GetScanEvent,
  GetValidateCode,
  SaveRegisterInfo,
  ValidateAliCode,
  JudgeMobile,
  GetServiceTerm,
  CheckTelPhone,
  SendSmsCode,
  Register,
  GetInitData,
} from '@/services/api';
import { catchException } from '@/utils/utils';

export default {
  namespace: 'register',

  state: {
    OEMInitData: {},
    QRCodeAndUnicode: {},
    token: {},
    validateAliCode: {},
    initData: {},
  },

  effects: {
    *getOEMData({ payload, callback }, { call, put }) {
      yield catchException(function*() {
        const response = yield call(getOEMData, payload);

        yield put({
          type: 'OEMInitData',
          payload: response,
        });
        callback && callback(response);
      });
    },
    *SendUserTel({ payload, callback }, { call, put }) {
      yield catchException(function*() {
        const response = yield call(SendUserTel, payload);

        yield put({
          type: 'getQRCode',
          payload: response,
        });
        callback && callback(response);
      });
    },
    *GetScanEvent({ payload, callback }, { call, put }) {
      yield catchException(function*() {
        const response = yield call(GetScanEvent, payload);

        yield put({
          type: 'getToken',
          payload: response,
        });
        callback && callback(response);
      });
    },
    *GetValidateCode({ payload, callback }, { call, put }) {
      yield catchException(function*() {
        const response = yield call(GetValidateCode, payload);

        yield put({
          type: 'getValidateCode',
          payload: response,
        });
        callback && callback(response);
      });
    },
    *SaveRegisterInfo({ payload, callback }, { call, put }) {
      yield catchException(function*() {
        const response = yield call(SaveRegisterInfo, payload);

        yield put({
          type: 'saveRegisterInfo',
          payload: response,
        });
        callback && callback(response);
      });
    },
    *ValidateAliCode({ payload, callback }, { call, put }) {
      yield catchException(function*() {
        const response = yield call(ValidateAliCode, payload);

        yield put({
          type: 'validateAliCode',
          payload: response,
        });
        callback && callback(response);
      });
    },
    *JudgeMobile({ payload, callback }, { call, put }) {
      yield catchException(function*() {
        const response = yield call(JudgeMobile, payload);

        callback && callback(response);
      });
    },
    *GetServiceTerm({ payload, callback }, { call, put }) {
      yield catchException(function*() {
        const response = yield call(GetServiceTerm, payload);

        callback && callback.success(response);
      });
    },

    // 新版
    *CheckTelPhone({ payload, callback }, { call, put }) {
      yield catchException(function*() {
        const response = yield call(CheckTelPhone, payload);

        callback && callback.success(response);
      });
    },

    *SendSmsCode({ payload, callback }, { call, put }) {
      yield catchException(function*() {
        const response = yield call(SendSmsCode, payload);

        callback && callback.success(response);
      });
    },

    *Register({ payload, callback }, { call, put }) {
      yield catchException(function*() {
        const response = yield call(Register, payload);
        callback && callback.success(response);
      });
    },

    *GetInitData({ payload, callback }, { call, put }) {
      yield catchException(function*() {
        const response = yield call(GetInitData, payload);
        document.querySelector('link[rel="icon"]').href = response.Data.icoPath;
        if (response.Code === 0) {
          yield put({
            type: 'initData',
            payload: response.Data,
          });
          callback && callback.success(response);
        }else {
          callback && callback.error(response);
        }
      });
    },
  },

  reducers: {
    OEMInitData(state, { payload }) {
      return {
        ...state,
        OEMInitData: payload,
      };
    },
    getQRCode(state, { payload }) {
      return {
        ...state,
        QRCodeAndUnicode: payload,
      };
    },
    getToken(state, { payload }) {
      return {
        ...state,
        token: payload,
      };
    },
    getValidateCode(state, { payload }) {
      return {
        ...state,
        validateCode: payload,
      };
    },
    saveRegisterInfo(state, { payload }) {
      return {
        ...state,
        saveRegisterInfo: payload,
      };
    },
    validateAliCode(state, { payload }) {
      return {
        ...state,
        validateAliCode: payload,
      };
    },
    initData(state, { payload }) {
      return {
        ...state,
        initData: payload,
      };
    },
  },
};
