import {
  GetLoginScanEvent,
  CheckNewLogin,
  LoginSendSmsCode,
  VerificationCode,
  GetScanResult
} from '@/services/api';
import { catchException } from '@/utils/utils';
export default {
  namespace: 'login',

  state: {
    loginGetLoginScanEvent: {}
  },

  effects: {

    // 新
    *GetLoginScanEvent({ payload, callback }, { call, put }) {
      const response = yield call(GetLoginScanEvent, payload);
      yield catchException(function* () {
        yield put({
          type: 'saveloginGetLoginScanEvent',
          payload: response,
        });
      })
      callback && callback.success(response);
    },

    *CheckNewLogin({ payload, callback }, { call, put }) {
      const response = yield call(CheckNewLogin, payload);
      yield catchException(function* () {
        callback && callback.success(response);
      })
    },

    *LoginSendSmsCode({ payload, callback }, { call, put }) {
      const response = yield call(LoginSendSmsCode, payload);
      yield catchException(function* () {
        callback && callback.success(response);
      })
    },

    *VerificationCode({ payload, callback }, { call, put }) {
      const response = yield call(VerificationCode, payload);
      yield catchException(function* () {
        callback && callback.success(response);
      })
    },

    *GetScanResult({ payload, callback }, { call, put }) {
      yield catchException(function* () {
        const response = yield call(GetScanResult, payload);
        callback && callback.success(response);
      })
    },

  },

  reducers: {
    saveloginGetLoginScanEvent(state, { payload }) {
      return {
        ...state,
        loginGetLoginScanEvent: payload,
      };
    },
  },
};
