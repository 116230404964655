import {
  ValidateTelPhone,
  SaveNewPassword,
  SetNewPwd,
  FindPwdSendSmsCode,
  FindPwdCheckTelPhone
} from '@/services/api';
import { catchException } from '@/utils/utils';
export default {
  namespace: 'findPwd',

  state: {
    validateTelPhone: {},
    saveNewPassword: {}
  },

  effects: {
    *ValidateTelPhone({ payload, callback }, { call, put }) {
      const response = yield call(ValidateTelPhone, payload);
      yield catchException(function* () {
        yield put({
          type: 'validateTelPhone',
          payload: response,
        });
        callback && callback(response);
      })
    },
    *SaveNewPassword({ payload, callback }, { call, put }) {
      const response = yield call(SaveNewPassword, payload);
      yield catchException(function* () {
        yield put({
          type: 'saveNewPassword',
          payload: response,
        });
        callback && callback(response);
      })
    },

    // 新版
    *FindPwdCheckTelPhone({ payload, callback }, { call, put }) {
      const response = yield call(FindPwdCheckTelPhone, payload);
      yield catchException(function* () {
        callback && callback.success(response);
      })
    },

    *FindPwdSendSmsCode({ payload, callback }, { call, put }) {
      const response = yield call(FindPwdSendSmsCode, payload);
      yield catchException(function* () {
        callback && callback.success(response);
      })
    },

    *SetNewPwd({ payload, callback }, { call, put }) {
      const response = yield call(SetNewPwd, payload);
      yield catchException(function* () {
        callback && callback.success(response);
      })
    },
  },

  reducers: {
    validateTelPhone(state, { payload }) {
      return {
        ...state,
        validateTelPhone: payload,
      };
    },
    saveNewPassword(state, { payload }) {
      return {
        ...state,
        saveNewPassword: payload,
      };
    },
  },
};
