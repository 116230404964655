"use strict";

require("core-js/es6/promise");

require("core-js/es6/set");

require("core-js/es6/object");

require("core-js/es6/number");

require("core-js/es6/array");

require("url-polyfill");