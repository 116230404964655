import { message } from 'antd';
let errorCode={
    30000:'服务不可用',
    30001:'缺少参数',
    30002:'参数非法',
    30003:'产品未授权',
    30004:'签名错误',
    30005:'OEM厂商不存在',
    30006:'应用ID不归属于该OEM厂商',
    30007:'店铺应用不存在',
    30008:'店铺应用未有任何功能授权',
    30009:'当前店铺不属于当前商家',
    30010:'注冊Token失效',
    50001:'该手机号已被注册过',
    50003:'获取验证码失败',
    50004:'注册失败',
    50005:'获取登录页面信息失败',
    50006:'登录失败，请检查手机号和密码是否正确',
    50007:'此手机未注册或手机号无效',
    50008:'新密码设置失败',
    50009:'登录二维码失效',
    50010:'登录失败，该用户还没注册过',
    50012:'短信验证码错误',
    50013:'短信次数已用完',
    50014:'获取OEM数据失败',
    50015:'获取注册二维码失败',
    50016:'验证码不正确',
    50017:'注册二维码失效',
    50020:'扫码拒绝授权',
    50021:'登录需要安全验证',
    50022:'登录安全验证手机号不存在',
    50024:'手机号未注册，请先注册账号',
    50025:'扫码登录二维码失效',
    50026:'注册条款未配置'
}

export default function showErrorMsg(res){
    let code=res.Code;
    if(code && errorCode[code]){
        if(code==30002){
            message.error(res.Msg);
        }else{
            //message.error(errorCode[code]);
        }
    }
}