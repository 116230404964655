import request from '@/utils/request';
import {stringify} from 'qs';
import {formDataCode} from '@/utils/utils';

  // 账号密码校验
  export async function AliCheckLogin(params) {
    return request('/api/Login/AliCheckLogin', {
      method: 'POST',
      headers:{
        'Content-Type':"application/x-www-form-urlencoded",        
     },
     body: formDataCode(params)
    });
  }

    // 账号登录验证码发送
    export async function SendAliSmsCode(params) {
      return request('/api/Login/SendAliSmsCode', {
        method: 'POST',
        headers:{
          'Content-Type':"application/x-www-form-urlencoded",        
       },
       body: formDataCode(params)
      });
    }



    export async function SendRegSmsCode(params) {
      return request('/api/Register/SendRegSmsCode', {
        method: 'POST',
        headers:{
          'Content-Type':"application/x-www-form-urlencoded",        
       },
       body: formDataCode(params)
      });
    }

    export async function SendAliSmsCodes(params) {
      return request('/api/FindPwd/SendAliSmsCode', {
        method: 'POST',
        headers:{
          'Content-Type':"application/x-www-form-urlencoded",        
       },
       body: formDataCode(params)
      });
    }



